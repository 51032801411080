import { computed, inject } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const baseClasses = 'text-sm uppercase';
const stripedHeadCellClasses =
  'even:bg-white even:dark:bg-gray-900 odd:dark:bg-gray-800 odd:bg-gray-50';
const listHeadCellClasses =
  'bg-white border border-secondary-300 [&:not(:last-child)]:border-l-0 [&:not(:last-child):not(:first-child)]:border-r-0 [&:last-child]:border-r-0';
const floatHeadCellClasses = 'bg-white border-b-14 border-white';
export function useTableHeadCellClasses(): {
  tableHeadCellClasses: Ref<string>;
} {
  const isColumnsStriped = inject('stripedColumns');
  const isTableList = inject('list');
  const isTableFloat = inject('float');

  const tableHeadCellClasses = computed(() => {
    return classNames(baseClasses, {
      [stripedHeadCellClasses]: isColumnsStriped,
      [listHeadCellClasses]: isTableList,
      [floatHeadCellClasses]: isTableFloat,
    });
  });

  return {
    tableHeadCellClasses,
  };
}
